import React from 'react';
import Empty from '../components/Empty';

type ComponentModule = Promise<{
  default: React.ComponentType<any>;
}>;
type ImportComponent = () => ComponentModule;
function retry(componentImport: ImportComponent, retryCount: number): ComponentModule {
  return new Promise((resolve) => {
    componentImport()
      .then(resolve)
      .catch((e) => {
        if (retryCount > 1) {
          console.warn('lazy_import_error', { error: e, code: 1 });
          // 尝试重新加载
          retry(componentImport, retryCount - 1).then(resolve);
        } else {
          console.error('lazy_import_error', e);
          resolve({ default: Empty });
        }
      });
  });
}

export default function (componentImport: ImportComponent, retryCount = 2) {
  return React.lazy(() => retry(componentImport, retryCount));
}
