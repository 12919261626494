import { throttle } from '@/common/util';
import { useEffect, useRef } from 'react';

export default function (fn: (...p: number[]) => void, delay = 200): void {
  const ref = useRef(fn);
  ref.current = fn;
  useEffect(() => {
    const handleScroll = throttle(() => {
      ref.current(document.documentElement.scrollTop);
    }, delay);
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [delay]);
}
