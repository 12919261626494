import { useRef, useEffect } from 'react';

export default function (
  selector: string,
  fn: (...p: number[]) => void,
  deps: any[] = [],
  delay = 0,
) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    function query(count: number) {
      const dom: any = document.querySelector(selector);
      if (dom && dom.offsetHeight) {
        fnRef.current(dom.offsetHeight, dom.offsetTop);
      } else {
        count < 2000 &&
          setTimeout(() => {
            query(++count);
          }, 100);
      }
    }
    setTimeout(() => {
      query(0);
    }, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
