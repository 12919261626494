import React, { Suspense, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import BottomNav from './BottomNav';
import './App.scss';
import { PageTab, PageTabValue, ReportPageType, TabName, TabValue } from './types';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { getUrlParamByKey, pushState, replaceParam } from '@/module/url';
import { LoadingContainer } from '@/components/BasicLoading/loadingElements';
import classNames from 'classnames';
import { trackAutoExpo, trackClick, trackImpress, trackView } from '@/common/track';
import ErrorInfo from '@/components/ErrorInfo';
import { formatMsg, formatValue } from '@/locales';
import { setConfigurePage } from '@/common/biz';
import useHomeTab from './useHomeTab';
import TabBar from './TabBar';
import { EPointerKey, reporter } from '@/common/pointerHandler';
import lazyImport from '@/common/lazyImport';
import { useOnEventCallback } from '@/hooks/useOnEventCallback';
import { callNavigateUrl } from '@/bridge';
import { getCookie } from '@/module/cookie';
import { isShopeeMainApp } from '@/common/util';
import { queryUnreadChatCount } from './model';
import { isEcHome } from './help';

let tabValue = getUrlParamByKey('tab') as PageTabValue;
if (!TabName[tabValue]) {
  tabValue = 'home';
}


const initTab = TabName[tabValue];
trackAutoExpo(ReportPageType[tabValue]);
trackView();

const ComponentsList: {
  key: TabValue;
  visited: boolean;
  Com: React.LazyExoticComponent<React.ComponentType<any>>;
}[] = [
  {
    key: TabName.home,
    visited: initTab == TabName.home,
    Com: lazyImport(() => import('../homePage')),
  },
  {
    key: TabName.products,
    visited: initTab == TabName.products,
    Com: lazyImport(() => import('../productList')),
  },
  {
    key: TabName.education,
    visited: initTab == TabName.education,
    Com: lazyImport(() => import('../education')),
  },
  {
    key: TabName.policies,
    visited: initTab == TabName.policies,
    Com: lazyImport(() => import('../myPolices')),
  },
];
const CACHE_PAGE_SCROLL: {
  [K in TabValue]: number;
} = {
  [TabName.home]: 0,
  [TabName.products]: 0,
  [TabName.education]: 0,
  [TabName.policies]: 0,
};
let isChanging = false;
const homeRightItemInfo = PFC.getPFC<Record<string, any>>('homeRightItem') || {};
const { switch: canShowRightItem, whiteList } = homeRightItemInfo || {};
function App() {
  const [currentTab, setCurrentTab] = useState<`${TabName}`>(initTab);
  const [errInfo, setErrInfo] = useState<{
    [K in TabValue]?: string;
  }>({});
  const [chatResp, setChatResp] = useState<Record<string, any>>({
    applyRedDot: false,
  });
  useLayoutEffect(() => {
    if (isChanging) {
      isChanging = false;
      window.scrollTo(0, CACHE_PAGE_SCROLL[currentTab]);
    }
  });
  const userId = getCookie('userid');

  const getRightItemConfig = useCallback(
    (iconUrl: string) => {
      // 这里读取不到whiteList时默认全量展示, 所以返回true
      const userCanShowRightItem = whiteList?.length ? whiteList.includes(userId) : true;
      if (!isShopeeMainApp || !canShowRightItem || !userCanShowRightItem || !iconUrl) {
        return;
      }
      return {
        items: [
          {
            type: 'button',
            key: 'chat',
            iconUrl,
            // iconType: 'delete',
            // badgeType: 'dot',
            // badgeCount: 2,
          },
        ],
      };
    },
    [userId],
  );

  useOnEventCallback(() => {
    // 埋点
    trackClick({
      page_section: 'homepage',
      target_type: 'chat_homepage',
    });
    // 点击跳转PFC配置的链接
    const link = formatValue(homeRightItemInfo?.jumpLink, { userId });
    if (chatResp.applyRedDot) {
      const rightItemsConfig = getRightItemConfig(homeRightItemInfo?.iconUrl);
      if (rightItemsConfig) {
        trackImpress({ page_section: 'homepage', target_type: 'chat_homepage' });
        setConfigurePage({
          isTransparent: 1,
          hideShadow: true,
          rightItemsConfig,
        });
      }
    }
    setChatResp({
      ...chatResp,
      applyRedDot: false,
    });
    callNavigateUrl({}, link);
  });
  useEffect(() => {
    if (canShowRightItem) {
      queryUnreadChatCount().then((res) => {
        const applyRedDot = !!res?.unread_count;
        setChatResp({
          applyRedDot,
        });
        const rightItemsConfig = getRightItemConfig(
          homeRightItemInfo?.[applyRedDot ? 'iconUrlRedDot' : 'iconUrl'],
        );
        if (rightItemsConfig) {
          trackImpress({ page_section: 'homepage', target_type: 'chat_homepage' });
          setConfigurePage({
            isTransparent: 1,
            hideShadow: true,
            rightItemsConfig,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onChangeTab = useMemoizedFn((tab: TabValue) => {
    if (currentTab == tab) return;
    CACHE_PAGE_SCROLL[currentTab] = window.pageYOffset;
    ComponentsList.some((item) => {
      if (item.key == tab) {
        if (!item.visited) {
          reporter(EPointerKey.BEFORE_JS, { origin_time: Date.now() });
        }
        item.visited = true;
        return true;
      }
    });
    isChanging = true;
    pushState(replaceParam('tab', PageTab[tab]));
    errInfo[tab] = '';

    setErrInfo({ ...errInfo });
    setCurrentTab(tab);
  });
  const retry = useMemoizedFn(() => {
    errInfo[currentTab] = '';

    setErrInfo({ ...errInfo });
  });
  const showError = useMemoizedFn((txt?: string | number) => {
    errInfo[currentTab] = `${txt || formatMsg('common.component.basecomponent.errortext')}`;

    setErrInfo({ ...errInfo });
  });

  const { useTapBack } = useHomeTab();
  useTapBack(currentTab);

  return (
    <div className={classNames('app', `app_${PageTab[currentTab]}`)}>
      <TabBar
        tabFrom={currentTab}
        rightItemsFixedConfig={getRightItemConfig(
          homeRightItemInfo[chatResp.applyRedDot ? 'iconUrlOnDarkRedDot' : 'iconUrlOnDark'],
        )}
        rightItemsTransConfig={getRightItemConfig(
          homeRightItemInfo[chatResp.applyRedDot ? 'iconUrlRedDot' : 'iconUrl'],
        )}
      />
      <Suspense fallback={<LoadingContainer cls="app_home-loading"></LoadingContainer>}>
        {ComponentsList.map(({ key, Com, visited }) => (
          <div
            key={key}
            className={classNames('app_item', {
              show: currentTab == key,
            })}
          >
            {visited ? (
              <>
                {errInfo[key] ? (
                  <ErrorInfo retry={retry} errorText={errInfo[key]} />
                ) : (
                  <Com showError={showError} />
                )}
              </>
            ) : null}
          </div>
        ))}
      </Suspense>
      <BottomNav onChangeTab={onChangeTab} tabFrom={currentTab}></BottomNav>
    </div>
  );
}

export default App;
