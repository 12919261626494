import React, { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { isIOS, isShopeeApp } from '@/common/util';
import { setConfigurePage } from '@/common/biz';
import ChangeableTabBar from '@/components/TabBar';
import { formatMsg } from '@/locales';
import { TabName, TabValue } from '../types';
import './index.scss';
import { isEcHome } from '../help';

interface IRightItemsConfig {
  items: {
    type: string;
    key: string;
    iconUrl: string;
  }[];
}

export interface ITabBar {
  tabFrom: TabValue;
  rightItemsTransConfig?: IRightItemsConfig;
  rightItemsFixedConfig?: IRightItemsConfig;
}
function getTabBarConfig(key: TabValue) {
  let policiesTitle = formatMsg('My Policies');

  
  policiesTitle = formatMsg('My Insurance');
  

  

  let productsTitle = formatMsg('Products');

  

  const TabBarConfig = {
    [TabName.home]: {
      title: formatMsg('Insurance'),
      isTransparent: 1,
    },
    
    [TabName.products]: {
      title: productsTitle,
      isTransparent: 0,
    },
    
    
    [TabName.education]: {
      title: formatMsg('Education'),
      isTransparent: 1,
    },
    
    [TabName.policies]: {
      title: policiesTitle,
      isTransparent: 0,
    },
  };
  return TabBarConfig[key];
}

function FixedTabBar(props: { title: string; rightItemsFixedConfig?: IRightItemsConfig }) {
  const { title, rightItemsFixedConfig } = props;
  useEffect(() => {
    setConfigurePage({
      title,
      isTransparent: 1,
      navbarStyle: 0,
      statusBarStyle: 0,
      hideShadow: true,
      rightItemsConfig: rightItemsFixedConfig,
    });
  }, [title, rightItemsFixedConfig]);

  if (!isShopeeApp) return null;

  return (
    <>
      <div className={classNames('tab-bar', { ios: isIOS() }, 'tab-bar__holder')}></div>
      <div className={classNames('tab-bar', { ios: isIOS() }, 'tab-bar__affix')}></div>
    </>
  );
}

function TabBar(props: ITabBar) {
  const { title, isTransparent } = getTabBarConfig(props.tabFrom);
  const { rightItemsTransConfig, rightItemsFixedConfig } = props;
  // 加限制条件判断 仅Home页展示
  const rightConfig = useMemo(() => {
    return props.tabFrom === 'Home'
      ? {
          rightItemsTransConfig,
          rightItemsFixedConfig,
        }
      : {};
  }, [props.tabFrom, rightItemsTransConfig, rightItemsFixedConfig]);

  return useMemo(
    () =>
      isTransparent ? (
        <ChangeableTabBar title={title} fixTitle={true} gradualHeight={80} {...rightConfig} />
      ) : (
        <FixedTabBar title={title} {...rightConfig} />
      ),
    [isTransparent, rightConfig, title],
  );
}

export default memo(TabBar);
