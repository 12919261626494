import { unregisterHandler, call } from '@/bridge';
import { useEffect, useRef } from 'react';

export function useOnEventCallback(fn: (key: string) => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    const onEventCallback = call('onEventCallback', async function (key: string) {
      fnRef.current(key);
    });
    return () => {
      unregisterHandler(onEventCallback, 'onEventCallback');
    };
  }, []);
}
