import request from '@/common/request';

export interface TaskCount {
  reminders: { fe_status: string; counts: string }[];
  counts: number;
}
export function queryTodoTasksCount(): Promise<TaskCount> {
  return new Promise((resolve) => {
    request({
      method: 'post',
      url: `/api/h5/standalone/query_todo_tasks_count`,
    })
      .then((data) => {
        if (!data) {
          console.error('queryTodoTasksCount', data);
          return;
        }
        resolve(data.data);
      })
      .catch(() => {
        // 不需要处理，因为如果异常， request 已经统一上报，且当前如果查询失败，我不需要做任何的提示
      });
  });
}

export interface ChatCount {
  unread_count: number;
}

export function queryUnreadChatCount(): Promise<ChatCount> {
  return new Promise((resolve) => {
    request({
      method: 'post',
      url: `/api/h5/standalone/chat/query_unread_count`,
    })
      .then((data) => {
        if (!data) {
          console.error('queryUnReadChatCountError', data);
          return;
        }
        resolve(data.data);
      })
      .catch(() => {
        // 不需要处理，因为如果异常， request 已经统一上报，且当前如果查询失败，我不需要做任何的提示
      });
  });
}
